

var endpoint = '/user'
export default class UserService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }

  getMyRoles(){
    return this.axios.get(`${endpoint}/myroles`)
  }

  save(user) {
    if (user.id) {
      return this.axios.put(`${endpoint}/${user.id}`, user)
    } else {
      return this.axios.post(`${endpoint}/`, user)
    }
  }

  delete(id) {
    return this.axios.delete(`${endpoint}/${id}`)
  }

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }
}
