import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('../views/pages/account/register'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters['auth/loggedIn']) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'home' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    // ADMIN
    {
        path: '/admin/patient',
        name: 'patient',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/patient/index')
    },
    {
        path: '/admin/patient/detail',
        name: 'patient-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/patient/detail')
    },
    {
        path: '/admin/patient/print/:id',
        name: 'patient-print',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/patient/print')
    },
    {
        path: '/admin/patient/upload',
        name: 'patient-upload',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/patient/upload')
    },
    {
        path: '/admin/professional',
        name: 'professional',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/professional/index')
    },
    {
        path: '/admin/user',
        name: 'user',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/user/index')
    },
    // PROCEDURES
    {
        path: '/procedure/list',
        name: 'procedure',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/procedure/index')
    },
    {
        path: '/procedure/map',
        name: 'procedure-map',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/procedure/map')
    },
    {
        path: '/procedure/calendar',
        name: 'procedure-calendar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/procedure/calendar')
    },
    {
        path: '/procedure/prof',
        name: 'procedure-prof',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/procedure/procedure-prof')
    },
]
