import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import axios from '@/services/http-commons'
import moment from 'moment'

import "@/assets/scss/app.scss";

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

import VueTheMask from 'vue-the-mask'


Vue.use(VueTheMask)

Vue.use(VueToast, {
    // One of the options
    position: 'top'
})

Vue.prototype.$http = axios
axios.interceptors.request.use(
  (config) => {
    console.log('MAIN -  interceptor: ')
    // console.log('store.state: ', store.state)
    var token = null
    if (store.state.auth.currentUser) {
      token = store.state.auth.currentUser.stsTokenManager.accessToken
    }
    if (token) {
      config.headers.common['Authorization'] = "Bearer " + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  
  response => {
    return response
  },
error => {
    console.log("Inter 2 Error: ", error)
    if (error.response.status) {
      console.log("Inter 2 status: ", error.response.status)
      if(error.response.status === 401 || error.response.data.code === "401"){
        
        console.log(error.response.headers["fbToken"])
        router.push("/logout")
      }

      return error
    }
  }
);


const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
